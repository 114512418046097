<script>
import { layoutComputed } from "@/state/helpers";
import appConfig from "../../app.config.json";
import { SimpleBar } from "simplebar-vue3";

export default {
  components: { SimpleBar },
  data() {
    return {
      appConfig,
      settings: {
        minScrollbarLength: 60,
      },
    };
  },
  computed: {
    ...layoutComputed,
    layoutType: {
      get() {
        return this.$store ? this.$store.state.layout.layoutType : {} || {};
      },
    },
  },
  watch: {
    $route: {
      handler: "onRoutechange",
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    setTimeout(() => {
      if (document.querySelectorAll(".navbar-nav .collapse")) {
        let collapses = document.querySelectorAll(".navbar-nav .collapse");
        collapses.forEach((collapse) => {
          // Hide sibling collapses on `show.bs.collapse`
          collapse.addEventListener("show.bs.collapse", (e) => {
            e.stopPropagation();
            let closestCollapse = collapse.parentElement.closest(".collapse");
            if (closestCollapse) {
              let siblingCollapses =
                closestCollapse.querySelectorAll(".collapse");
              siblingCollapses.forEach((siblingCollapse) => {
                if (siblingCollapse.classList.contains("show")) {
                  siblingCollapse.classList.remove("show");
                }
              });
            } else {
              let getSiblings = (elem) => {
                // Setup siblings array and get the first sibling
                let siblings = [];
                let sibling = elem.parentNode.firstChild;
                // Loop through each sibling and push to the array
                while (sibling) {
                  if (sibling.nodeType === 1 && sibling !== elem) {
                    siblings.push(sibling);
                  }
                  sibling = sibling.nextSibling;
                }
                return siblings;
              };
              let siblings = getSiblings(collapse.parentElement);
              siblings.forEach((item) => {
                if (item.childNodes.length > 2)
                  item.firstElementChild.setAttribute("aria-expanded", "false");
                let ids = item.querySelectorAll("*[id]");
                ids.forEach((item1) => {
                  item1.classList.remove("show");
                  if (item1.childNodes.length > 2) {
                    let val = item1.querySelectorAll("ul li a");

                    val.forEach((subitem) => {
                      if (subitem.hasAttribute("aria-expanded"))
                        subitem.setAttribute("aria-expanded", "false");
                    });
                  }
                });
              });
            }
          });

          // Hide nested collapses on `hide.bs.collapse`
          collapse.addEventListener("hide.bs.collapse", (e) => {
            e.stopPropagation();
            let childCollapses = collapse.querySelectorAll(".collapse");
            childCollapses.forEach((childCollapse) => {
              let childCollapseInstance = childCollapse;
              childCollapseInstance.hide();
            });
          });
        });
      }
    }, 0);
  },
  methods: {
    onRoutechange(ele) {
      this.initActiveMenu(ele.path);
      setTimeout(() => {
        if (document.getElementsByClassName("mm-active").length > 0) {
          const currentPosition =
            document.getElementsByClassName("mm-active")[0].offsetTop;
          if (currentPosition > 500)
            if (this.$refs.isSimplebar)
              this.$refs.isSimplebar.value.getScrollElement().scrollTop =
                currentPosition + 300;
        }
      }, 300);
    },

    initActiveMenu(ele) {
      setTimeout(() => {
        if (document.querySelector("#navbar-nav")) {
          let a = document
            .querySelector("#navbar-nav")
            .querySelector('[href="' + ele + '"]');

          if (a) {
            a.classList.add("active");
            let parentCollapseDiv = a.closest(".collapse.menu-dropdown");
            if (parentCollapseDiv) {
              parentCollapseDiv.classList.add("show");
              parentCollapseDiv.parentElement.children[0].classList.add(
                "active"
              );
              parentCollapseDiv.parentElement.children[0].setAttribute(
                "aria-expanded",
                "true"
              );
              if (
                parentCollapseDiv.parentElement.closest(
                  ".collapse.menu-dropdown"
                )
              ) {
                parentCollapseDiv.parentElement
                  .closest(".collapse")
                  .classList.add("show");
                if (
                  parentCollapseDiv.parentElement.closest(".collapse")
                    .previousElementSibling
                )
                  parentCollapseDiv.parentElement
                    .closest(".collapse")
                    .previousElementSibling.classList.add("active");
              }
            }
          }
        }
      }, 1000);
    },
  },
};
</script>

<template>
  <div class="container-fluid">
    <div id="two-column-menu"></div>

    <template v-if="layoutType === 'twocolumn'">
      <SimpleBar class="navbar-nav" id="navbar-nav">

      </SimpleBar>
    </template>

    <template v-else>
      <ul class="navbar-nav h-100" id="navbar-nav">
        <li class="menu-title">
          <span>{{ $t("t-category-home") }}</span>
        </li>

        <li class="nav-item">
          <router-link class="nav-link menu-link" to="/">
            <i class="ri-home-3-line"></i>
            <span data-key="t-home">{{ $t("t-home") }}</span>
          </router-link>
        </li>

        <li class="nav-item">
          <router-link class="nav-link menu-link" to="/home/settings">
            <i class="ri-user-settings-line"></i>
            <span data-key="t-profile-settings">{{ $t("t-profile-settings") }}</span>
          </router-link>
        </li>

        <li class="nav-item">
          <router-link class="nav-link menu-link" to="/home/skin">
            <i class="ri-t-shirt-line"></i>
            <span data-key="t-profile-skin">{{ $t("t-profile-skin") }}</span>
          </router-link>
        </li>

        <li class="nav-item">
          <router-link class="nav-link menu-link" to="/home/cape">
            <i class="ri-t-shirt-air-line"></i>
            <span data-key="t-profile-cape">{{ $t("t-profile-cape") }}</span>
          </router-link>
        </li>

        <li class="menu-title">
          <span data-key="t-category-donate">{{ $t("t-category-donate") }}</span>
        </li>

        <li class="nav-item">
          <router-link class="nav-link menu-link" to="/home/balance">
            <i class="ri-coins-fill"></i>
            <span data-key="t-donate-fill">{{ $t("t-donate-fill") }}</span>
          </router-link>
        </li>

        <li class="nav-item">
          <router-link class="nav-link menu-link" to="/donate">
            <i class="ri-shopping-cart-2-line"></i>
            <span data-key="t-donate-shop">{{ $t("t-donate-shop") }}</span>
          </router-link>
        </li>

        <li class="menu-title">
          <span data-key="t-category-other">{{ $t("t-category-other") }}</span>
        </li>

        <li class="nav-item">
          <a
              class="nav-link menu-link"
              href="#sidebarDashboards"
              data-bs-toggle="collapse"
              role="button"
              aria-expanded="false"
              aria-controls="sidebarDashboards"
          >
            <i class="ri-link-m"></i>
            <span data-key="t-other-links"> {{ $t("t-other-links") }}</span>
          </a>

          <div class="collapse menu-dropdown" id="sidebarDashboards">
            <ul class="nav nav-sm flex-column">
              <li class="nav-item">
                <a
                    :href="appConfig.links.site"
                    class="nav-link"
                    data-key="t-crm"
                    target="_blank"
                >
                  {{ $t("t-other-site") }}
                </a>
              </li>
              <li class="nav-item">
                <a
                    :href="appConfig.links.vk"
                    class="nav-link custom-abc"
                    data-key="t-analytics"
                    target="_blank"
                >
                  {{ $t("t-other-vk") }}
                </a>
              </li>
              <li class="nav-item">
                <a
                    :href="appConfig.links.discord"
                    class="nav-link"
                    data-key="t-crm"
                    target="_blank"
                >
                  {{ $t("t-other-discord") }}
                </a>
              </li>
              <li class="nav-item">
                <a
                    :href="appConfig.links.wiki"
                    class="nav-link"
                    data-key="t-crm"
                    target="_blank"
                >
                  {{ $t("t-other-wiki") }}
                </a>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </template>
  </div>
  <!-- Sidebar -->
</template>
