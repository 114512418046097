import axios from 'axios';
import { printError } from '@/helpers/request';
// import { useToast } from "vue-toastification";
// import store from "@/state/store";
//
// const toast = useToast();

export async function searchUsers({ login, limit, paginate, page }) {
   return await axios.get('/users', {
        params: {
            login,
            limit,
            paginate,
            page
        }
    }).then(({ data }) => {
        if (data.data) {
            data.data = data.data.map(addUserLooks);
        } else {
            data = data.map(addUserLooks);
        }

        return data;
   }).catch(printError);
}

function addUserLooks(user) {
    return user;
}